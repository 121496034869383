import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

//Auth

let auth = set => ({
    ecommerce: {name:''},
    addAuthData: (data) => set(state => ({ ecommerce: data })),
    removeAuthData: () => set(state => ({ ecommerce: {} })),
});

auth = persist(auth, {name: 'ecommerce'});
auth = devtools(auth);

export const useAuthStore = create(auth);