import React from 'react'
import Illustration1 from '../../assets/illustrations/illustration1.png'
import Illustration2 from '../../assets/illustrations/illustration2.png'
import Illustration3 from '../../assets/illustrations/illustration3.png'

export const Illustrations = () => {
  return (
    <aside id='illustrations-container'>
        <img className='h-100' src={Illustration1} alt="Wompi art" />
        <img className='h-100' src={Illustration2} alt="Wompi art" />
        <img className='h-100' src={Illustration3} alt="Wompi art" />
    </aside>
  )
}
