import { post as restPost } from '../services/shared/rest.service';

export const loginService = async (key, _key, storeId, testMode) => {
    try {
        const endpoint = `/onboarding/authentication?shop=${storeId}`;
        const body = {
            publicKey: key,
            privateKey: _key,
            isTest: testMode,
        };
        const result = await restPost(endpoint, body);
        return result.data;
    } catch(error) {
        throw error.message;
    }
};
