// LIBRARIES
import React from 'react';
import { motion } from 'framer-motion'
import queryString from 'query-string';
import { useLocation } from "react-router";
// SERVICES
import { useAuthStore } from '../../store';
import { titleAnimations, subtitleAnimations } from '../../services/shared/framermotion.service';
import { ButtonSecondary } from '../units/shared/Button';

const Hello = () => {
    const ecommerce = useAuthStore((state) => state.ecommerce.name);
    const location = useLocation();
    const { store = "", country = "" } = queryString.parse(location.search);

    const redirectUrls = {
        CO: 'https://comercios.wompi.co/',
        PA: 'https://comercios.wompi.pa/'
      };

    return (
        <div className="welcome-text">
            <motion.h1
                className={`main-title ${ecommerce}`}
                variants={titleAnimations}
                initial='initial'
                animate='animate'
            >
                <strong>Hola ¡{store.split('.myshopify.com')}!</strong>
            </motion.h1>
            <motion.p
                className="subtitle mt-3 fw-normal"
                variants={subtitleAnimations}
                initial='initial'
                animate='animate'
            >
                Ya estás a pocos pasos de recibir pagos y vender con Wompi.
                Para continuar, asegúrate de tener en una cuenta activa con Wompi.
            </motion.p>
            <motion.p
                className="subtitle mt-3 fw-normal"
                variants={subtitleAnimations}
                initial='initial'
                animate='animate'
            >
                ¿Aún no tienes cuenta?
            </motion.p>
            <motion.a className="no-decoration"
                variants={subtitleAnimations}
                initial='initial'
                animate='animate' href={redirectUrls[country]} target='_blank' rel="noreferrer" >
                <ButtonSecondary
                    ecommerce={ecommerce}
                    btnTxt="Créala aquí"
                />
            </motion.a>
        </div>
    );
};

export default Hello;
