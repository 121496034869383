import React,{useState} from 'react';
import { ButtonBig } from "../../units/shared/Button";
import { InputsSimple } from "../../units/shared/Input";
import {showError,showSuccess} from '../../../services/shared/alert.service'
import { useLocation } from "react-router";
import queryString from 'query-string';
import { loginService } from '../../../services/onboarding.service';
import { useAuthStore } from '../../../store'
import { ColorRing } from 'react-loader-spinner';

// ! THIS IS NOT A MOCK OR DEV TEST, THIS IS FOR USERS DIRECTLY
export const TestForm = () => {
    const location = useLocation();
    const { store = "" } = queryString.parse(location.search);
    const [apiKey, setApiKey] = useState("");
    const [apiToken, setApiToken] = useState("");
    const [isLoading,setIsLoading] = useState(false)
    const [isFailed, setIsFailed] = useState(false);

    const ecommerce = useAuthStore((state) => state.ecommerce.name);

    const handleChange = (e) => {
        switch (e.target.id) {
          case "apiKey":
            setApiKey(e.target.value.trim());
            break;
          case "apiToken":
            setApiToken(e.target.value.trim());
            break;
          default:
            break;
        }
    if (isFailed) {
      setIsFailed(false)
    }
      };
      
      
  const signIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
      try {
        const result = await loginService(
          apiKey,
          apiToken,
          store,
          true
        );

        if (result && result.success) {
          showSuccess(result.message);
          if ( store ) {
            const url = `https://${store}/services/payments_partners/gateways/105d1e5a6d6a1d56136e7ad1ba2b4d06/settings`;
            window.open(url);
          }
        }
        if (result.error) {
          showError(result.error);
          setIsFailed(true)
        }
        setIsLoading(false);
      } catch (error) {
        setIsFailed(true)
        showError(error);
        setIsLoading(false);
      }
    }
  ;


    return( 
        <form onSubmit={signIn}>    
        {isLoading &&
          <ColorRing
            visible={true}
            height="120"
            width="120"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="loading-spinner"
            colors={['#DFFF61', '#00825A', '#00825A', '#00825A', '#c7e455']}
          />}  
            <InputsSimple
            value={apiKey}
            id="apiKey"
            type="text"
            placeholder="Llave pública de prueba"
            onChangeFn={handleChange}
            isFailed={isFailed}
            />

            <InputsSimple
            value={apiToken}
            id="apiToken"
            type="text"
            placeholder="Llave privada de prueba"
            onChangeFn={handleChange}
            isFailed={isFailed}
            />

            <ButtonBig
            ecommerce={ecommerce}
            type="submit" 
            btnTxt="Conectar en modo prueba"
            showSpinner={isLoading}
            isDisabled={apiKey && apiToken ? false : true}
            extraClass="wompi"
            />
        </form>
        )
};
