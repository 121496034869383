import React,{useState} from "react";
import { TestForm } from "./LoginForm/TestForm";
import { DevForm } from "./LoginForm/DevForm";
import { motion,AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { showSuccess } from "../../services/shared/alert.service";
import { realFormAnimations,testFormAnimations  } from "../../services/shared/framermotion.service";

export const LoginForm = ({ testState }) => {
  const { testingMode, setTestingMode } = testState
  useEffect(() => {
    if ( testingMode ) {
      showSuccess('Las credenciales de producción han sido guardadas exitosamente.')
    }
  }, [testingMode]);
  

  return (
    <AnimatePresence exitBeforeEnter>
      {testingMode ? 
      <motion.div 
      key='collapse'        
      initial='initial'
      animate='animate'
      variants={testFormAnimations}
      className='w-100'>
        <TestForm 
        testingMode={testingMode}
        />
      </motion.div>
       :
        <motion.div 
          key='expanded'    
          initial='initial'
          animate='animate'
          exit='exit'
          variants={realFormAnimations}
          className='w-100'
        >
        <DevForm 
        testingMode={testingMode}
        onSuccess={()=>setTestingMode(!testingMode)}
        />
        </motion.div>
      }
  </AnimatePresence>
  );
};
