import React from 'react'
import { Outlet } from 'react-router-dom'
import { Illustrations } from '../../components/customers/Illustration'
import { LogoContainer } from '../../components/customers/LogoContainerShopify'
import Hello from '../../components/welcome/Hello'
import { useAuthStore } from '../../store'

const LayoutWompiShopify = () => {
  const ecommerce = useAuthStore((state) => state.ecommerce.name);

    return (
        <div className="container-fluid" id="welcome">
          <div className="row">
            <div className={`col col-xxl-6 welcome-grid ${ecommerce}`}>
              <LogoContainer />
              <Hello />
              <Illustrations />
            </div>
            <Outlet/>
          </div>
        </div>
    )
}
export default LayoutWompiShopify