import React from "react";

export const InputsSimple = ({
    value,
    id,
    type,
    placeholder,
    labelTxt,
    onChangeFn,
    onBlurFn,
    isFailed
  }) => {
    
    return (
      <div className="mb-xxl-2">
        <label htmlFor={id} className="form-label">
          {labelTxt}
        </label>
        <input
          type={type}
          className={`form-control p-2 p-xxl-3 ${isFailed && 'error-border'}`}
          id={id}
          placeholder={placeholder}
          onChange={onChangeFn}
          value={value}
          onBlur={onBlurFn}

        />
        <p className={`error-text m-0 mt-1 ${!isFailed && 'opacity-0'} `}>Las credenciales son inválidas.</p>
      </div>
    );
  };
  