import React from "react";
import { BrowserRouter,Route,Routes } from "react-router-dom";
import RoutesWompi from './customers/RoutesWompi';



export const ParentRoute = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="wompi/*" element={<RoutesWompi/>}/>
      </Routes>
    </BrowserRouter>
  );


};
