import { useEffect } from "react";
import { Route, Routes} from "react-router-dom";
import { useAuthStore } from "../../store";
import LoginContainer from "../../components/welcome/LoginContainer";
import LayoutWompiShopify from "../../pages/layouts/WompiShopify";



export default function RoutesWompi() {
  const cleanEcommerce = useAuthStore((state)=>state.removeAuthData)
  const addEcommerce = useAuthStore((state)=>state.addAuthData)
  useEffect(() => {
        cleanEcommerce()
        addEcommerce({
          name: 'secommerce'
        })
  }, [addEcommerce, cleanEcommerce])
  return (
        <>
          <Routes>
              <Route path="secommerce/" element={<LayoutWompiShopify />}>
                <Route path="welcome" element={<LoginContainer/>}/>
              </Route>
          </Routes>
        </>
  );
}
