import React, { useState } from 'react';
import { ButtonBig } from "../../units/shared/Button";
import { InputsSimple } from "../../units/shared/Input";
import { showError } from '../../../services/shared/alert.service'
import { useLocation } from "react-router";
import queryString from 'query-string';
import { loginService } from '../../../services/onboarding.service';
import { ModalSmall } from '../../units/shared/Modal';
import { useAuthStore } from '../../../store'
import { ColorRing } from 'react-loader-spinner'


export const DevForm = ({ testingMode, onSuccess }) => {
  const ecommerce = useAuthStore((state) => state.ecommerce.name);
  const location = useLocation();
  const { store = "" } = queryString.parse(location.search);
  const [apiKey, setApiKey] = useState("");
  const [apiToken, setApiToken] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [modalDisplay, setModalDisplay] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target
    switch ( id ) {
      case "apiKey":
        setApiKey( value.trim() );
        break;
      case "apiToken":
        setApiToken( value.trim() );
        break;
      default:
        break;
    }
    if (isFailed) {
      setIsFailed(false)
    }
  };

  const signIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (apiKey && apiToken) {
      try {
        const result = await loginService(
          apiKey,
          apiToken,
          store,
          testingMode
        );
        if (result && result.success) {
          setModalDisplay(!modalDisplay)
        }
        if (result.error) {
          showError(result.error);
          setIsFailed(true)
        }
        setIsLoading(false);
      } catch (error) {
        showError(error);
        setIsLoading(false);
        setIsFailed(true)
      }
    } else {
      showError("Verifique haber completado todos los campos");
      setIsLoading(false);
    }
  };


  return (
    <form onSubmit={signIn}>
      {isLoading &&
        <ColorRing
          visible={true}
          height="120"
          width="120"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="loading-spinner"
          colors={['#DFFF61', '#00825A', '#00825A', '#00825A', '#c7e455']}
        />}
      {modalDisplay &&
        <ModalSmall
          title='Credenciales de producción correctas'
          innerText={
          <>
            <p className='m-0'>
              Ahora, debes configurar tus credenciales en modo <strong>pruebas</strong>. 
              Esto te permitirá realizar compras de prueba en tu tienda en el caso que lo desees
            </p>
              <p>
                ¿Necesitas ayuda para encontrar estas credenciales de prueba?
              </p>
          </>
          }
            innerAnchorText='Mira este tutorial.'
            innerAnchorLink='#'
            customClassAnchor='fw-bold'
            ecommerce={ecommerce}
            defaultColors={false}
            modalShow={modalDisplay}
            onExitFn={onSuccess}
            />
        }
      <InputsSimple
        value={apiKey}
        id="apiKey"
        type="text"
        placeholder="Llave pública"
        onChangeFn={handleChange}
        isFailed={isFailed}
      />
      <InputsSimple
        value={apiToken}
        id="apiToken"
        type="text"
        placeholder="Llave privada"
        onChangeFn={handleChange}
        isFailed={isFailed}
      />
      <ButtonBig
        ecommerce={ecommerce}
        type="submit"
        btnTxt="Conectar"
        showSpinner={isLoading}
        isDisabled={apiKey && apiToken ? false : true}
        extraClass="blueButton"
      />
    </form>
  )
};
