import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Success from '../../../assets/illustrations/success.svg'
import { ButtonBig } from './Button';

export const ModalSmall = ({ title, titleImage, onExitFn, textColor, innerText, modalShow, innerAnchorText, innerAnchorLink, customButtonClass, defaultColors, customClassAnchor, ecommerce }) => {
    const [isOpen, setIsOpen] = useState(modalShow);
    const { Header, Title, Body, Footer } = Modal
    const hideModal = () => {
        setIsOpen(false);
        onExitFn()
    };
    return (
        <>
            <Modal className='modal-container p-2' show={isOpen} onHide={hideModal}>
                <Header className='d-flex align-items-center justify-content-center'>
                    <img src={Success} alt="Éxito" className='position-absolute start-0 ms-5 float-left' />
                    <Title className=' pe-1 p-xxl-3 rounded fw-bold ms-3 ms-xxl-4'>{title}</Title>
                </Header>
                <Body className=' bg-gray text-center d-flex flex-column align-center rounded'>
                    {innerText}
                    {(innerAnchorText || innerAnchorLink) &&
                        <a
                            href={innerAnchorLink}
                            target='_blank'
                            rel="noreferrer"
                            className={`safe-anchor-${defaultColors} ${customClassAnchor} `}>
                            {innerAnchorText}
                        </a>}
                </Body>
                <Footer>
                    <ButtonBig
                    ecommerce={ecommerce}
                    btnTxt="Entendido"
                    extraClass="wompi mb-4 mb-xxl-5"
                    onClickFn={hideModal}
                    />
                </Footer>
            </Modal>
        </>
    )
}