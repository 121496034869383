export const subtitleAnimations = {
    initial:{ opacity: 0, y: 20 },
    animate:{ opacity: 1, y: 0, transition:{duration: .5, delay: 1.5} },
  };

export const titleAnimations = {
    initial:{x: "-50vw"},
    animate:{x: '0', transition:{delay: .3, duration: 1}},
    transition:{ type: "spring", stiffness: 100 }
}

export const realFormAnimations = {
    animate:{ opacity: 1, y: 0, transition:{duration: .5} },
    initial:{ opacity: 0, y: 20 },
    exit:{opacity:0,rotateY:-180, transition:{duration:.7}}
}

export const testFormAnimations ={
    initial:{ opacity: 0, rotateY:180 },
    animate:{ opacity: 1, rotateY:0, transition:{duration: .7} }
}