import React, { useState } from "react";
import { LoginForm } from "./LoginForm";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const LoginContainer = () => {
  const location = useLocation();
  const { store = "", country = "" } = queryString.parse(location.search);
  const [testingMode, setTestingMode] = useState(false);

  return (
    <div className="col col-xxl-6 form-col">
      <div className="form-wrapper">
        <h2 className="small-title fw-bold">
          Ingresa tus credenciales de Wompi
        </h2>
        <p className="medium-text ">
          Conecta tu cuenta de
          <strong className="fw-bold"> Wompi </strong>
          con
          <strong className="fw-bold"> Shopify</strong>. Ingresa las llaves de
          <strong className="fw-bold">
            {" "}
            {testingMode ? "prueba" : "producción"}
          </strong>
          , que encontrarás en la sección
          <strong className="fw-bold">"Desarrolladores"</strong>.
        </p>
        <LoginForm
          testState={{
            testingMode: testingMode,
            setTestingMode: setTestingMode,
          }}
        />
        <p>
          ¿Tienes dudas?
          <a
            href={`https://soporte.wompi.${country}/hc/es-419/requests/new?ticket_form_id=360000591473`}
            target="_blank"
            rel="noreferrer"
            className="ms-1 no-decoration text-dark fw-bold"
          >
            Contáctanos
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginContainer;
