import React from 'react';



export const LogoContainer= () => {
    return (
        <div className="logo-container">
            <LogoWompi/>
        </div>
    )
}


const LogoWompi = () => {
    return(
        <svg width="100%" height="100%" viewBox="0 0 179 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M45.9026 5.3804C47.4757 5.04168 49.0684 4.87231 50.6809 4.87231C52.3326 4.87231 53.9253 5.04168 55.4591 5.3804L45.3126 42.4706C43.543 42.7717 41.8126 42.9222 40.1215 42.9222C38.5877 42.9222 36.8376 42.7717 34.8713 42.4706L27.7923 17.5744L20.5954 42.4706C18.8257 42.7717 17.1346 42.9222 15.5222 42.9222C13.9884 42.9222 12.2384 42.7717 10.272 42.4706L0.0665283 5.3804C1.67895 5.04168 3.27171 4.87231 4.84481 4.87231C6.45723 4.87231 8.04999 5.04168 9.62308 5.3804L16.23 31.5186L22.6011 5.3804C24.2529 5.04168 25.9833 4.87231 27.7923 4.87231C29.6015 4.87231 31.2925 5.04168 32.8655 5.3804L40.5344 31.6314L45.9026 5.3804Z" fill="#2C2A29"/>
            <path d="M69.2216 42.9786C66.1147 42.9786 63.4012 42.3765 61.0808 41.1721C58.7605 39.9677 56.9712 38.2553 55.7126 36.0348C54.4541 33.7766 53.8249 31.1798 53.8249 28.2441C53.8249 25.2709 54.4739 22.6928 55.7716 20.51C57.0694 18.2895 58.8785 16.577 61.1987 15.3727C63.5585 14.1683 66.2327 13.5662 69.2216 13.5662C72.3285 13.5662 75.0421 14.1871 77.3623 15.4291C79.6827 16.6335 81.4721 18.3459 82.7305 20.5664C83.989 22.787 84.6182 25.3462 84.6182 28.2441C84.6182 31.255 83.989 33.8708 82.7305 36.0913C81.4721 38.3117 79.6827 40.0242 77.3623 41.2286C75.0421 42.3953 72.3285 42.9786 69.2216 42.9786ZM69.2216 35.6397C71.306 35.6397 72.8987 35.0186 73.9998 33.7766C75.1404 32.497 75.7106 30.6528 75.7106 28.2441C75.7106 25.9107 75.1404 24.1042 73.9998 22.8246C72.8594 21.5449 71.2666 20.9051 69.2216 20.9051C67.2158 20.9051 65.6231 21.5637 64.4433 22.881C63.3028 24.1607 62.7325 25.9484 62.7325 28.2441C62.7325 30.6528 63.3028 32.497 64.4433 33.7766C65.5837 35.0186 67.1766 35.6397 69.2216 35.6397Z" fill="#2C2A29"/>
            <path d="M120.959 13.6226C124.263 13.6226 126.996 14.4129 129.158 15.9936C131.322 17.5743 132.404 19.682 132.404 22.3165V42.4141H123.614V25.3085C123.614 23.8407 123.123 22.7304 122.139 21.9778C121.195 21.1875 119.838 20.7923 118.069 20.7923C116.495 20.7923 114.981 21.0745 113.526 21.6391C113.998 22.881 114.234 24.2359 114.234 25.7037V42.4141H105.444V25.3085C105.444 23.8407 104.953 22.7304 103.97 21.9778C103.026 21.1875 101.669 20.7923 99.8993 20.7923C98.5622 20.7923 97.3037 20.9427 96.1239 21.2439V42.4141H87.2162L87.2751 17.2921C89.0055 16.1254 91.1293 15.2221 93.6462 14.5823C96.1632 13.9425 98.6212 13.6226 101.02 13.6226C102.829 13.6226 104.52 13.9049 106.093 14.4695C107.666 14.9963 109.043 15.749 110.223 16.7276C111.756 15.7866 113.487 15.0339 115.414 14.4695C117.341 13.9049 119.189 13.6226 120.959 13.6226Z" fill="#2C2A29"/>
            <path d="M149.541 13.6226C154.929 13.6226 159.137 14.9775 162.165 17.6873C165.233 20.3971 166.767 24.2359 166.767 29.2038C166.767 33.3062 165.705 36.637 163.581 39.1962C161.497 41.7177 158.33 42.9786 154.083 42.9786C152.707 42.9786 151.311 42.7716 149.895 42.3576C148.479 41.9059 147.161 41.285 145.943 40.4946V50.9386C144.999 51.6537 143.583 52.2935 141.696 52.8581C139.847 53.4226 138.293 53.7048 137.036 53.7048V15.8242C138.53 15.1468 140.378 14.6199 142.581 14.2435C144.822 13.8296 147.142 13.6226 149.541 13.6226ZM150.308 35.8654C152.786 35.8654 154.673 35.2633 155.972 34.0589C157.269 32.8546 157.918 30.8975 157.918 28.1877C157.918 25.9296 157.249 24.0665 155.913 22.5988C154.614 21.1309 152.667 20.3971 150.072 20.3971C148.775 20.3971 147.398 20.5099 145.943 20.7357V35.4703C147.319 35.7337 148.775 35.8654 150.308 35.8654Z" fill="#2C2A29"/>
            <path d="M174.341 9.84025C173.083 9.84025 172.002 9.42625 171.097 8.59826C170.231 7.73265 169.799 6.69765 169.799 5.4933C169.799 4.28895 170.251 3.27279 171.156 2.4448C172.061 1.57918 173.122 1.14636 174.341 1.14636C175.6 1.14636 176.682 1.57918 177.586 2.4448C178.49 3.27279 178.942 4.28895 178.942 5.4933C178.942 6.66001 178.49 7.67619 177.586 8.54182C176.682 9.40745 175.6 9.84025 174.341 9.84025ZM169.918 14.413C171.215 13.8862 172.709 13.6226 174.4 13.6226C176.053 13.6226 177.528 13.8862 178.825 14.413V42.4141H169.918V14.413Z" fill="#2C2A29"/>
            <path d="M8.81512 57.3363V51.5357C8.81512 50.953 9.24107 50.4531 9.83984 50.3478C12.799 49.8276 21.6131 48.3949 28.9301 48.3949C36.265 48.3949 45.3194 49.8346 48.334 50.3516C48.9359 50.4549 49.3643 50.9561 49.3643 51.5412V57.3665C49.3643 58.2084 48.4896 58.8292 47.634 58.6336C44.8848 58.0055 38.7977 56.9503 28.9301 56.9503C19.1793 56.9503 13.2739 57.9807 10.5732 58.6111C9.70998 58.8127 8.81512 58.1867 8.81512 57.3363Z" fill="#2C2A29"/>
        </svg>

    )
}