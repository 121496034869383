import React from "react";

export const ButtonBig = ({
    ecommerce,
    type='button',
    btnTxt,
    showSpinner,
    isDisabled,
    onClickFn,
    extraClass,
    onBlur,
  }) => {
    return (
      <div className="d-grid gap-2">
          <button
            onBlur={onBlur}
            type={type}
            className={` btn  ${ecommerce} btn-customprimary block mt-3 mt-xxl-4 mb-3 ${extraClass}`}
            onClick={onClickFn}
            disabled={isDisabled}
          >
            {showSpinner ? (
              <div className="spinner-grow spinner-grow-sm" role="status">
                <span className="visually-hidden">Cargando...</span>
              </div>
            ) : (
              btnTxt
            )}
          </button>
      </div>
    );
  };

  export const ButtonSecondary = ({
    ecommerce,
    type='button',
    btnTxt,
    extraClass,
  }) => {
    return (
      <div className="d-grid gap-2">
          <button
            type={type}
            className={`btn ${ecommerce} btn-customsecondary mt-3 mt-xxl-4 mb-3  fw-bold ${extraClass}`}
          >
            {btnTxt}
          </button>
      </div>
    );
  }